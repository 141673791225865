
import Vue, { PropType } from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';

import Slide from '@/components/Output/Slide.vue';
import { SlideVisibility } from '@/app/graphql';

export default Vue.extend({
  name: 'ActiveSlideContainer',

  components: {
    Slide,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    activeSlide: {
      type: Object as PropType<OutputSlide>,
      default: undefined,
    },
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    createLibrarySlide: {
      type: Boolean,
      default: false,
    },
    isShowColors:{
      type: Boolean,
      default: false,
    }
  },

  computed: {
    slideIsHidden(): boolean {
      return this.activeSlide?.visibility === SlideVisibility.Hidden;
    },
  },
});
