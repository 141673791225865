
import Vue from 'vue';
import Slide from './Slide.vue';
import { BaseDialog } from '@/shared/ui';
import { OutputSlide } from '@/shared/legacy/classes';
import { ValidationServiceContract, OutputServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

export default Vue.extend({
  name: 'AddSlideToLibraryDialog',

  components: { Slide, BaseDialog },

  data: (): {
    slideLabel: string;
    valid: boolean;
    transformedSlide: OutputSlide;
    targetLibrary: string;
    previewSlideWidth: number;
    saveSuccess: boolean;
  } => ({
    saveSuccess: false,
    previewSlideWidth: 370,
    targetLibrary: 'user',
    transformedSlide: null,
    valid: true,
    slideLabel: '',
  }),
  props: {
    active: {
      type: Boolean,
    },
    slide: {
      type: Object,
      required: true,
    },
    selectedAgency: {
      type: String,
      default: '',
    },
    selectedSubAgency: {
      type: String,
      default: '',
    },
    addingBlankSlide: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    targetLibraryItems(): { text: string; value: string }[] {
      const items = [{ text: 'User Library', value: 'user' }];

      if (this.isAdmin) {
        items.push({ text: 'Agency Library', value: 'agency' });
        items.push({ text: 'Agency and User Libraries', value: 'both' });
        if (this.isParentAgency) items.push({ text: 'Shared Library', value: 'shared' });
      }

      return items;
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);

      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
    previewSlide(): OutputSlide {
      return this.transformedSlide || this.slide;
    },
    loading(): boolean {
      return this.$store.state.output.outputLoading;
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    isParentAgency() {
      return this.$store.getters['auth/isParentAgency'];
    },
    slideWidth(): number {
      const screenWidth = this.$vuetify.breakpoint.width;
      if (!screenWidth) return 240;
      return (screenWidth * 0.8) / 4 - 45;
    },
    dialogIsOpen: {
      get(): boolean {
        return this.active;
      },
      set(): void {
        this.$emit('close-dialog', { saved: this.saveSuccess });
      },
    },
    colorScheme() {
      return this.$store.state.output.layoutColors;
    },
  },

  methods: {
    async setTransformedSlide(): Promise<void> {
      if (this.slide) {
        const outputService: OutputServiceContract = this.$container.get(Services.Output);
        this.transformedSlide = await outputService.prepareSlideForLibrary({ slide: this.slide });
      }
    },
    addSlideToLibrary(slide, targetLibrary, agency) {
      return this.$store.dispatch('output/addSlideToLibrary', {
        slide: slide,
        agencyId:
          this.isAdmin && (this.selectedAgency || this.selectedSubAgency)
            ? this.selectedSubAgency || this.selectedAgency
            : agency,
        targetLibrary: targetLibrary,
      });
    },
    addBlankSlide(slide) {
      return this.$store.dispatch('output/addBlankSlide', {
        slide: slide,
      });
    },
    async addSlide(): Promise<void> {
      await this.$refs.addSlideToLibraryLabel.validate();

      if (!this.valid) return;
      const { layout } = this.$store.state.output.activeOutput;
      const { Agency } = this.$store.state.auth.user;

      const labeledSlide = {
        ...this.previewSlide,
        layoutId: layout,
        label: this.slideLabel,
        ...(this.addingBlankSlide ? { name: this.slideLabel } : {}),
      };

      (this.addingBlankSlide
        ? this.addBlankSlide(labeledSlide)
        : this.addSlideToLibrary(labeledSlide, this.targetLibrary, Agency)
      )
        .then(slides => {
          if (slides && Array.isArray(slides)) {
            this.$store.dispatch('showSnackbar', { content: 'Slide has been added to library', color: 'success' });
            this.saveSuccess = true;
            this.dialogIsOpen = false;
          }
        })
        .catch(err => {
          this.$store.dispatch('showSnackbar', { content: 'Unable to save slide at this time', color: 'warning' });
          // eslint-disable-next-line no-console
          console.error('OutputSidebar/addSlideToLibrary', err);
        });
    },
  },

  mounted(): void {
    this.setTransformedSlide();
  },
});
